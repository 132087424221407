@import '../../scss/variables';

.container {
    background-color: $darkgrey;
    
    * {
        &,
        &:hover {
            color: $white; 
            
        }
    }
}

.mainContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 63px 0 70px;

    > * {
        flex-shrink: 0;
    }
}

.innerContainer {
    margin: 0 auto;
    max-width: 1488px;
}

.logoContainer,
.addressContainer {
    width: 25%;
}

.logoContainer {
    align-self: flex-start;
    padding-right: 24px;
    max-width: 319px;

    &,
    &:hover {
        border: none;
    }
}

.logo {
    height: auto;
}

.addressContainer {
    padding: 8px 12px 0;

    .addressTitle {
        display: block;
        font-size: 14px;
        font-family: $sans-serif-medium;
        font-weight: $sans-medium;
        margin-bottom: 8px;
    }

    .address {
        address {
            font-size: 14px;
            font-style: normal;
            line-height: 23px;
            opacity: .8;
            white-space: pre-wrap;
        }
    }
}

.linksContainer {
    column-count: 2;
    column-gap: 48px;
    padding-left: 24px;
    width: 50%;

    .linkListItem {
        display: block;

        // Reset default list item styles:
        &::before {
            content: none;
        }
        & + .linkListItem {
            margin-top: 8px;
        }
    }

    .link {
        font-size: 14px;
        line-height: 20px;       
        opacity: .8;

        &:hover {
            opacity: 1;
        }
    }
}

.socialContainer {
    align-items: center;
    display: flex;
    margin-top: 24px;
    margin-left: auto;
    width: calc(25% - 36px);     

    .socialIconLink {
        border: none;         
        opacity: .8;

        &:not(:first-of-type) {
            margin-left: 18px;
        }

        &:hover {
            opacity: 1;
        }
    }

    .socialIcon {
        font-size: 23px;         
    }
}

.copyrightContainer {
    &::before {
        box-shadow: 0 1px 4px 0 rgba(163, 163, 163, 0.37);
        border: solid 1px #2c2c2c;
        content: '';
        display: block;
        height: 1px;
        left: 0;
        position: absolute;
        opacity: .8;
        width: 100%;
    }

    .copyright {
        display: block;
        font-size: 14px;
        line-height: 23px;
        opacity: .8;
        padding: 20px 0 16px;
        text-align: center;         
    }
}

@media (max-width: $desktop-smaller) {
    .mainContainer {
        padding: 48px 0 58px;
    }
    
    .linksContainer {
        column-gap: 32px;
    }
}

@media (max-width: $mobile-larger) {
    .mainContainer {
        display: block;
    }

    .logoContainer,
    .addressContainer,
    .linksContainer,
    .socialContainer {
        width: 100%;
    }

    .logoContainer {
        padding-right: 0;
    }

    .addressContainer {
        padding: 32px 0 24px;
    }

    .linksContainer {
        padding-left: 0;
    }

    .socialContainer {
        margin-top: 32px;

        .socialIconLink {
            &:not(:first-of-type) {
                margin-left: 24px;
            }
        }
    }
}

@media (max-width: $mobile) {
    .mainContainer {
        padding: 32px 0;
    }
    
    .linksContainer {
        column-gap: 24px;
    }
    
    .copyrightContainer {
        .copyright {
            padding: 16px 0 12px;
        }
    }
}